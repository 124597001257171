<script>
import Layout from '../../layouts/main';
import { api } from '@/state/services';
import { VclList } from 'vue-content-loading';

export default {
  locales: {
    pt: {
      'Payment receipts': 'Comprovantes',
      'Send receipt': 'Enviar comprovante',
      'See receipt': 'Ver comprovante',
    },
    es: {
      'Payment receipts': 'Recibos',
      'Send receipt': 'Enviar recibo',
      'See receipt': 'Ver recibo',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      table: {
        heade: [
          'Date', 'Bank', 'Value', 'URL', 'Status'
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      order: {
        id: this.$route.params.id,
      }
    }
  },
  methods: {
    getDeposits() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false
      this.table.empty = false;

      if (this.order.id) {
        api
          .get('wallet/order/deposit/' + this.order.id)
          .then(response => {
            if (response.data.status==='success') {
              this.table.body = response.data.list;
            } else {
              this.table.body = null;
            }
          })
          .catch(error => {
            this.table.errored = error
          })
          .finally(() => {
            this.table.loading = false
            if (this.table.body=='' || this.table.body==null) {
              this.table.empty = true
            }
          });
      }
    }
  },
  mounted() {
    this.getDeposits();
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Payment receipts') }}</h4>
          <router-link :to="'/wallet/order/deposit/send/' + this.order.id" class="btn btn-primary">
            {{ t('Send receipt') }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td v-for="(data,row) in td" :key="row">
                      <div v-if="row === 'id'">
                        <strong>#{{ data }}</strong>
                      </div>
                      <div v-else-if="row === 'value'">
                        U$S {{ data }}
                      </div>
                      <div v-else-if="row === 'url'">
                        <a :href="data" target="_blank" rel="noopener noreferrer">{{ t('See receipt') }}</a>
                      </div>
                      <div v-else-if="row === 'status'">
                        <span v-if="data === 'send'" class="badge badge-soft-warning font-size-12">{{ t('Pending') }}</span>
                        <span v-else-if="data === 'approved'" class="badge badge-soft-success font-size-12">{{ t('Approved') }}</span>
                        <span v-else-if="data === 'disapproved'" class="badge badge-soft-danger font-size-12">{{ t('Canceled') }}</span>
                      </div>
                      <div v-else class="notranslate">
                        {{ data }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>